import React from 'react'

function Intro(props) {
  return (
    <section className="intro">
      <div className="container">
        <p className="intro__paragraph text--3">{props.paragraph1}</p>

        {props.paragraph2 ? (
          <p className="intro__paragraph text--3">{props.paragraph2}</p>
        ) : null}
      </div>
    </section>
  )
}

export default Intro
