import React from 'react'

function TwoColumns(props) {
  return (
    <section
      className={'two-columns ' + (props.right ? 'two-columns--right' : '')}
    >
      <div className="container">
        {props.heading ? (
          <h2
            className={
              'two-columns__heading text--3 underline ' +
              (props.headingLong ? 'two-columns__heading--long' : '')
            }
          >
            {props.heading}
          </h2>
        ) : null}

        <div className="two-columns__content">
          <div className="two-columns__single-column">
            <p className="two-columns__paragraph text--5">{props.paragraph1}</p>
            <p className="two-columns__paragraph text--5">{props.paragraph2}</p>
          </div>
          <div className="two-columns__single-column">
            <p className="two-columns__paragraph text--5">{props.paragraph3}</p>
            <p className="two-columns__paragraph text--5">{props.paragraph4}</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TwoColumns
