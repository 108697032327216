import React from 'react'

import SEO from '../../components/seo'

import Layout from '../../components/layout'

import SectionHeader from '../../components/SectionHeader/SectionHeader'
import TextBlock from '../../components/TextBlock/TextBlock'
import List from '../../components/List/List'
import TwoColumns from '../../components/TwoColumns/TwoColumns'
import WorkWithUs from '../../components/WorkWithUs/WorkWithUs'
import BottomNav from '../../components/BottomNav/BottomNav'
import Intro from '../../components/Intro/Intro'

const DigitalMarketing = () => {
  const socialMedia = [
    {
      id: 1,
      text: 'We identify your niche.',
    },
    {
      id: 2,
      text: 'Identify and narrow down your target audience.',
    },
    {
      id: 3,
      text: 'Study your competition to see how we can perform better.',
    },
    {
      id: 4,
      text: 'Create tailored content speaking to your target audience.',
    },
    {
      id: 5,
      text:
        'Increase your brand’s awareness amongst people by consistent message and repetition.',
    },
    {
      id: 6,
      text:
        'Provide and analyze reports which help you understand your social media from behind the scenes.',
    },
  ]

  return (
    <Layout>
      <SEO
        title="Digital Marketing"
        keywords={[
          `Emersoft`,
          `application`,
          `react`,
          `London`,
          `digital agency`,
        ]}
      />

      <SectionHeader>
        A great website that holds people's attention is the start. Now you need
        them to visit it.
      </SectionHeader>
      <Intro paragraph1="Here’s how you get more traffic to your website." />
      <TextBlock
        heading="Social Media"
        paragraph1="The purpose of social media channels is to attract and communicate with the RIGHT people and get them to go to your website."
        paragraph2="You can have the best content in the universe but if you target it at the wrong audience you will just be banging your head against the wall. Social Media also help you gain credibility and recognizability. Here's how we deal with that."
      />
      <List data={socialMedia} additionalClass="list-section--bg" />
      <TwoColumns
        heading="Email Newsletter:"
        paragraph1="Not much like in the early 00’s but e-mail campaigns are still one of the most effective marketing tools."
        paragraph2="According to Campaign Monitor, every $1 spent on email campaigns generates about $40 as a return on investment (ROI). The cake is still pretty big."
        paragraph3="Your NEW e-mail campaigns will be gently speaking to your prospects’ needs without peddling  anything “on a first date”."
        paragraph4="Instead, they get e-mails that are based on a casual, no-salesy conversation,  slowly leading through the selling process and ultimately make them buy."
      />
      <Intro paragraph1="If you don’t show in Google, you don’t exist." />
      <TwoColumns
        heading="SEO Optimization:"
        headingLong
        paragraph1="Basic SEO optimization helps your website get positioned higher in Google.  Why is that so important?"
        paragraph2="Some people can directly look for your company by its name and - they’ll find it, no issue. Things get tricky when people search by key phrases based on the problem they want to solve."
        paragraph3="Without a good SEO optimisation, your website will be nowhere in sight. That means less visiting traffic and less clients."
        paragraph4="Statistically, about 75% of clicks go to the first page of search results. Ranking higher in Google gives you an edge over other companies. You also communicate that you have an established position and an authority in your niche."
      />
      <WorkWithUs />
      <BottomNav
        additionalClass="bottom-nav--border-top"
        heading="See More"
        firstLink="/services/websites"
        firstHeading="Websites/Design"
        secondLink="/services/hire-developers"
        secondHeading="Supporting Services"
      />
    </Layout>
  )
}

export default DigitalMarketing
